import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const accordionItemVariants = cva('rocco-accordion-item', {
    variants: {
        variant: {
            default: 'border-b border-b-gray-900/30',
            lightBorder: 'border-b border-b-gray-900/10',
            darkBorder: 'border-b border-b-black',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    OmitStylesComponentProps<typeof AccordionPrimitive.Item> &
        VariantProps<typeof accordionItemVariants>
>(({ variant, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={accordionItemVariants({ variant })}
        {...props}
    />
));

AccordionItem.displayName = 'AccordionItem';
