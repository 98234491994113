import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { OmitStylesComponentProps } from '../../types';
import { cva, VariantProps } from 'class-variance-authority';

const accordionContentVariants = cva('accordion-content', {
    variants: {
        variant: {
            default:
                'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

const accordionContentChildVariants = cva('rocco-accordion-content', {
    variants: {
        variant: {
            default: 'pb-4 pt-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    OmitStylesComponentProps<typeof AccordionPrimitive.Content> &
        VariantProps<typeof accordionContentVariants>
>(({ children, variant, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={accordionContentVariants({ variant })}
        {...props}
    >
        <div className={accordionContentChildVariants({ variant })}>
            {children}
        </div>
    </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;
