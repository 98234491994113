import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown, ChevronRight, Plus, Minus } from 'lucide-react';

import { OmitStylesComponentProps } from '../../types';

import { cva, type VariantProps } from 'class-variance-authority';

const accordionHeaderVariants = cva('rocco-accordion-header flex', {
    variants: {
        variant: {
            default: '',
            spacious: '',
            narrow: '',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

const accordionTriggerVariants = cva(
    'rocco-accordion-trigger flex flex-1 items-center justify-between transition-all [&[data-state=open]>svg]:rotate-180',
    {
        variants: {
            variant: {
                narrow: 'py-3 font-medium md:hover:underline',
                default: 'py-4 font-medium md:hover:underline',
                spacious: 'py-5 font-medium md:data-[state=open]:pb-0',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

const accordionIconVariants = cva(
    'rocco-accordion-icon shrink-0 transition-transform duration-200',
    {
        variants: {
            variant: {
                default: 'h-4 w-4',
                spacious: 'h-4 w-4',
                narrow: 'h-4 w-4',
            },
            startDirection: {
                down: '',
                right: 'rotate-[-90deg]',
            },
        },
        defaultVariants: {
            variant: 'default',
            startDirection: 'down',
        },
    },
);

const ICON_COMPONENTS = {
    chevronDown: ChevronDown,
    chevronRight: ChevronRight,
    plus: Plus,
    minus: Minus,
} as const;

type IconType = keyof typeof ICON_COMPONENTS;

export const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    OmitStylesComponentProps<typeof AccordionPrimitive.Trigger> &
        VariantProps<typeof accordionTriggerVariants> &
        VariantProps<typeof accordionIconVariants> & {
            icon?: IconType;
            openIcon?: IconType;
            isOpen?: boolean;
        }
>(
    (
        {
            children,
            variant,
            startDirection,
            icon = 'chevronDown',
            openIcon,
            isOpen = false,
            ...props
        },
        ref,
    ) => {
        const IconComponent =
            ICON_COMPONENTS[isOpen && openIcon ? openIcon : icon];

        return (
            <AccordionPrimitive.Header
                className={accordionHeaderVariants({ variant })}
            >
                <AccordionPrimitive.Trigger
                    ref={ref}
                    className={accordionTriggerVariants({ variant })}
                    {...props}
                >
                    {children}
                    <IconComponent
                        className={accordionIconVariants({
                            startDirection: startDirection,
                            variant: variant,
                        })}
                    />
                </AccordionPrimitive.Trigger>
            </AccordionPrimitive.Header>
        );
    },
);

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
