import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { OmitStylesComponentProps } from '../../types';

import { cva, VariantProps } from 'class-variance-authority';

const accordionVariants = cva('rocco-accordion', {
    variants: {
        variant: {
            default: 'w-full',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const Accordion = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Root>,
    OmitStylesComponentProps<typeof AccordionPrimitive.Root> &
        VariantProps<typeof accordionVariants>
>(({ variant, ...props }, ref) => (
    <AccordionPrimitive.Root
        ref={ref}
        className={accordionVariants({ variant })}
        {...props}
    />
));

Accordion.displayName = 'Accordion';
